:root, [data-amplify-theme] {
    --amplify-colors-background-primary: white;
    --amplify-colors-background-secondary: var(green);
    --amplify-colors-brand-primary-10: var(green);
    --amplify-colors-brand-primary-80: var(green);
    --amplify-colors-brand-primary-90: var(green);
    --amplify-colors-brand-primary-100: var(green);
    --amplify-colors-font-interactive: green;
    --amplify-components-tabs-item-active-color: green;
    --amplify-components-tabs-item-focus-color: green;
    --amplify-components-tabs-item-hover-color: green;
  }

  .amplify-button[data-variation='primary'] {
    background: linear-gradient(
  to right,
  #20c997, /* Your hex color */
  #eafbf6
);
  }